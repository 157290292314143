import React, {useEffect} from 'react';
import styled from 'styled-components';
import DesStack from '../components/comp/stack/DesStack';
import DevStack from '../components/comp/stack/DevStack';
import Htext from '../components/comp/Htext';
import Contact from '../components/layouts/Contact';
import Footer from '../components/layouts/Footer';
import Meetrix from '../images/meex.png';
import { getAbout } from '../features/profile/profileSlice';
import { useSelector, useDispatch } from 'react-redux';
import AOS from 'aos';
import 'aos/dist/aos.css';
import SkeletalLoad from '../components/comp/SkeletalLoad';


const AboutStyle = styled.section`
  margin-top: 8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .stackstyle {
    width: 100%;
    margin-top: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: 768px) {
      flex-direction: column;
    }
  }
`;
const AboutDiv = styled.div`
  display: flex;
  margin: 2rem 8rem;
  justify-content: center;
  align-items: center;
  img {
    width: 20rem;
    height: 20rem;
    margin-left: 9rem;
  }
  .right-sec {
    padding-left: 10rem;
    margin-right: 9rem;
  }
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    margin: 2rem 1rem;
    img {
    width: 14rem;
    height: 14rem;
    margin-left: 0rem;
  }
    .right-sec {
      padding: 2rem 1rem;
      margin-right: 0rem;
      
    }
  }
`;
const About = () => {
  const dispatch = useDispatch();
  const { profile, loading } = useSelector((state) => state.profile);
  useEffect(() => {
    AOS.init({
      duration: 1000,
      offset: 200,
      easing: 'ease-in-out',
      once: true,
    });
  }, []);

  useEffect(() => {
    dispatch(getAbout());
  }, [dispatch]);
  return (
    
    <AboutStyle data-aos="fade-up">
      <Htext text='About' id='gradient-text' />
      <AboutDiv>
        <div className='left-sec'>
          <img src={Meetrix} alt='' />
        </div>
        {!loading ?
        ( <div className='right-sec font-sty' dangerouslySetInnerHTML={{ __html: profile.about }}>
         
        </div>
        ) : (
          <div className='right-sec font-sty gradientbtn'>
           <SkeletalLoad/>
          </div>
        )
      }
      </AboutDiv>
      <div className='stackstyle'>
        <DevStack />
        <DesStack />
      </div>

      <Contact />
      <Footer />
    </AboutStyle>
  );
};

export default About;
