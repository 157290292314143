import * as React from 'react';
const UX = ({className}) => (

<svg  className={className} x="0px" y="0px"
	 viewBox="0 0 24 24">
<style type="text/css">
	
</style>
<circle className="ux0" cx="12" cy="12" r="9.9"/>
<g>
	<path className="ux1" d="M6.3,7.9v4.8c0,1.8,0.8,2.6,1.9,2.6c1.2,0,2-0.8,2-2.6V7.9h1.1v4.7c0,2.5-1.3,3.5-3.1,3.5
		c-1.7,0-2.9-0.9-2.9-3.5V7.9H6.3z"/>
	<path className="ux1" d="M17.5,16l-1-1.8c-0.4-0.7-0.7-1.1-0.9-1.6h0c-0.2,0.5-0.5,0.9-0.9,1.6l-1,1.8h-1.2l2.5-4.1l-2.4-4h1.2l1.1,1.9
		c0.3,0.5,0.5,0.9,0.7,1.4h0c0.2-0.5,0.4-0.9,0.7-1.4l1.1-1.9h1.2l-2.5,3.9l2.5,4.1H17.5z"/>
</g>
</svg>

);
export default UX;