import React, {useEffect} from 'react';
import BlueFile from '../svg/BlueFile';
import styled from 'styled-components';
import Htext from '../components/comp/Htext';
import DevProjects from '../components/layouts/DevProjects';
import AOS from 'aos';
import 'aos/dist/aos.css';

const DevStyle = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 7rem;
  margin-bottom: 8rem;
  .ico-sty {
    width: 5rem;
    margin-bottom: 1rem;
  }
  #dt-sty {
    padding: 1rem 15rem;
    text-align: center;
  }
  .htext-sty {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    margin: 0 1rem;
  }
  /************************************ */
  @media only screen and (max-width: 768px) {
    #dt-sty {
      padding: 1rem 1rem;
      text-align: left;
    }
    .ico-sty {
      width: 2.5rem;
    }
  }
`;

const Dev = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      offset: 200,
      easing: 'ease-in-out',
      once: true,
    });
  }, []);
  return (
    <DevStyle>
      <div className='ico-sty'>
        <BlueFile />
      </div>
      <div className='htext-sty'>
        <Htext text='Web & Mobile Development' id='gradient-text' />
      </div>
      <div data-aos='fade-up'>
        <DevProjects id='margin-top' />
      </div>
    </DevStyle>
  );
};

export default Dev;
