import {  createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const url= process.env.REACT_APP_BASEURL
const apiKey= process.env.REACT_APP_APIKEY

export const getAbout = createAsyncThunk('profile/getAbout', async () => {
    const response = await axios.get(`${url}/api/profile`,{
      headers: {
        'x-api-key': apiKey,
      },
    });
    return response.data;
  });
  
  export const profileSlice = createSlice({
    name: 'profile',
    initialState: {
      profile: "",
      loading: false,
      success: false,
      error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
      .addCase(getAbout.pending, (state) => {
        // Handle pending state
        state.loading = true;
      })
      .addCase(getAbout.fulfilled, (state, action) => {
        state.profile = action.payload;
        state.loading = false;
        state.error = null;
        state.success = true;
      })
      .addCase(getAbout.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.profile = null; // Set the profile to null in case of rejection
      })
    },
  });
  

export default profileSlice.reducer;