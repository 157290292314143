import * as React from 'react';
const OrangeFile = () => (
  <svg viewBox='0 0 287.7 287.7'>
    <path
      transform='rotate(-6.673 9.23 152.62)'
      className='sv0'
      d='M8.56 142.32h1.36V163H8.56z'
    />
    <path
      transform='rotate(-6.673 11.931 175.71)'
      className='sv0'
      d='M11.26 170.53h1.36v10.43h-1.36z'
    />
    <path
      className='sv0'
      d='m101.76 100.42-1.6-4.49c-2.27-6.36-8.63-10.32-15.34-9.53l-63.76 7.46c-7.95.93-13.65 8.13-12.72 16.09l18.15 155.18c.64 5.45 5.58 9.36 11.03 8.72l228.14-26.68c5.45-.64 9.36-5.58 8.72-11.03L258.69 102c-.64-5.45-5.58-9.36-11.03-8.72l-132.48 15.49c-5.87.68-11.44-2.78-13.42-8.35z'
    />
    <g id='anifile'>
      <path
        transform='rotate(-11.275 81.964 89.76)'
        className='sv1'
        d='M45.24 39.15h73.5v101.26h-73.5z'
      />
      <path
        transform='rotate(-11.275 81.964 89.76)'
        className='sv2'
        d='M45.24 39.15h73.5v101.26h-73.5z'
      />
      <g className='sv3'>
        <path
          className='sv0'
          d='m98.51 56.84-44.4 8.85c-2.15.43-4.25-.97-4.68-3.12-.43-2.15.97-4.25 3.12-4.68l44.4-8.85a3.977 3.977 0 0 1 1.56 7.8zM101.78 73.26l-44.4 8.85c-2.15.43-4.25-.97-4.68-3.12-.43-2.15.97-4.25 3.12-4.68l44.4-8.85a3.977 3.977 0 0 1 1.56 7.8zM105.05 89.68l-44.4 8.85c-2.15.43-4.25-.97-4.68-3.12-.43-2.15.97-4.25 3.12-4.68l44.4-8.85c2.15-.43 4.25.97 4.68 3.12a3.97 3.97 0 0 1-3.12 4.68z'
        />
        <path
          className='sv1'
          d='m108.32 106.1-44.4 8.85c-2.15.43-4.25-.97-4.68-3.12-.43-2.15.97-4.25 3.12-4.68l44.4-8.85c2.15-.43 4.25.97 4.68 3.12.44 2.16-.96 4.26-3.12 4.68zM99.46 124.94l-32.27 6.43c-2.15.43-4.25-.97-4.68-3.12-.43-2.15.97-4.25 3.12-4.68l32.27-6.43c2.15-.43 4.25.97 4.68 3.12.43 2.16-.96 4.26-3.12 4.68z'
        />
      </g>
      <path
        transform='rotate(-7.793 181.297 68.934)'
        className='sv1'
        d='M144.55 18.3h73.5v101.26h-73.5z'
      />
      <path
        transform='rotate(-7.793 181.297 68.934)'
        className='sv2'
        d='M144.55 18.3h73.5v101.26h-73.5z'
      />
      <g className='sv3'>
        <path
          className='sv0'
          d='m199.79 37.06-44.86 6.14c-2.18.3-4.18-1.22-4.48-3.4a3.971 3.971 0 0 1 3.4-4.48l44.86-6.14c2.18-.3 4.18 1.22 4.48 3.4a3.986 3.986 0 0 1-3.4 4.48zM202.06 53.65l-44.86 6.14c-2.18.3-4.18-1.22-4.48-3.4a3.971 3.971 0 0 1 3.4-4.48l44.86-6.14c2.18-.3 4.18 1.22 4.48 3.4a3.986 3.986 0 0 1-3.4 4.48zM171.83 74.69l-12.36 1.69c-2.18.3-4.18-1.22-4.48-3.4a3.971 3.971 0 0 1 3.4-4.48l12.36-1.69c2.18-.3 4.18 1.22 4.48 3.4.3 2.17-1.23 4.18-3.4 4.48z'
        />
        <path
          className='sv1'
          d='m195.8 71.41-12.36 1.69c-2.18.3-4.18-1.22-4.48-3.4a3.971 3.971 0 0 1 3.4-4.48l12.36-1.69c2.18-.3 4.18 1.22 4.48 3.4a3.991 3.991 0 0 1-3.4 4.48z'
        />
        <path
          className='sv0'
          d='m206.6 86.83-44.86 6.14c-2.18.3-4.18-1.22-4.48-3.4a3.971 3.971 0 0 1 3.4-4.48l44.86-6.14c2.18-.3 4.18 1.22 4.48 3.4.3 2.18-1.23 4.18-3.4 4.48zM196.61 105.1l-32.6 4.46c-2.18.3-4.18-1.22-4.48-3.4a3.971 3.971 0 0 1 3.4-4.48l32.6-4.46c2.18-.3 4.18 1.22 4.48 3.4.3 2.17-1.23 4.18-3.4 4.48z'
        />
      </g>
      <path
        transform='rotate(-4.98 109.944 82.1)'
        className='sv1'
        d='M73.16 31.45h73.5v101.26h-73.5z'
      />
      <path
        transform='rotate(-4.98 109.944 82.1)'
        className='sv4'
        d='M73.16 31.45h73.5v101.26h-73.5z'
      />
      <g className='sv3'>
        <path
          className='sv0'
          d='m129.94 51.15-45.11 3.93a3.98 3.98 0 1 1-.69-7.93l45.11-3.93a3.98 3.98 0 1 1 .69 7.93zM131.39 67.83l-45.11 3.93a3.98 3.98 0 1 1-.69-7.93l45.11-3.93a3.98 3.98 0 1 1 .69 7.93zM132.85 84.51l-45.11 3.93a3.98 3.98 0 1 1-.69-7.93l45.11-3.93a3.98 3.98 0 0 1 4.31 3.62c.18 2.19-1.43 4.12-3.62 4.31zM134.3 101.19l-45.11 3.93a3.98 3.98 0 1 1-.69-7.93l45.11-3.93a3.98 3.98 0 1 1 .69 7.93z'
        />
        <path
          className='sv1'
          d='m123.43 118.95-32.78 2.86a3.98 3.98 0 1 1-.69-7.93l32.78-2.86a3.98 3.98 0 0 1 4.31 3.62c.18 2.19-1.43 4.12-3.62 4.31z'
        />
      </g>
      <path
        transform='rotate(-2.763 197.184 75.898)'
        className='sv1'
        d='M160.35 25.24h73.5V126.5h-73.5z'
      />
      <path
        transform='rotate(-2.763 197.184 75.898)'
        className='sv4'
        d='M160.35 25.24h73.5V126.5h-73.5z'
      />
      <g className='sv3'>
        <path
          className='sv0'
          d='m218.31 45.74-45.22 2.18a3.971 3.971 0 0 1-4.16-3.78 3.971 3.971 0 0 1 3.78-4.16l45.22-2.18a3.971 3.971 0 0 1 4.16 3.78 3.97 3.97 0 0 1-3.78 4.16zM219.12 62.46l-45.22 2.18a3.971 3.971 0 0 1-4.16-3.78 3.971 3.971 0 0 1 3.78-4.16l45.22-2.18a3.971 3.971 0 0 1 4.16 3.78 3.971 3.971 0 0 1-3.78 4.16zM187.17 80.77l-12.46.6a3.971 3.971 0 0 1-4.16-3.78 3.971 3.971 0 0 1 3.78-4.16l12.46-.6a3.971 3.971 0 0 1 4.16 3.78c.1 2.19-1.59 4.05-3.78 4.16zM211.33 79.6l-12.46.6a3.971 3.971 0 0 1-4.16-3.78 3.971 3.971 0 0 1 3.78-4.16l12.46-.6a3.971 3.971 0 0 1 4.16 3.78c.1 2.19-1.59 4.06-3.78 4.16zM220.74 95.91l-45.22 2.18a3.971 3.971 0 0 1-4.16-3.78 3.971 3.971 0 0 1 3.78-4.16l45.22-2.18a3.971 3.971 0 0 1 4.16 3.78c.1 2.19-1.59 4.06-3.78 4.16zM209.18 113.23l-32.86 1.59a3.971 3.971 0 0 1-4.16-3.78 3.971 3.971 0 0 1 3.78-4.16l32.86-1.59a3.971 3.971 0 0 1 4.16 3.78 3.965 3.965 0 0 1-3.78 4.16z'
        />
      </g>
    </g>
    <path
      className='sv1'
      d='m122.12 103.3-1.17-4.39c-1.66-6.23-7.59-10.08-14.3-9.3l-63.76 7.46c-7.95.93-14.25 8-14.07 15.78l3.62 151.9c.13 5.34 4.65 9.15 10.11 8.51l228.14-26.68c5.45-.64 9.77-5.48 9.65-10.82l-3.13-131.31c-.13-5.34-4.65-9.15-10.11-8.51l-132.48 15.49c-5.86.68-11.05-2.69-12.5-8.13z'
    />
    <path
      d='m122.12 103.3-1.17-4.39c-1.66-6.23-7.59-10.08-14.3-9.3l-63.76 7.46c-7.95.93-14.25 8-14.07 15.78l3.62 151.9c.13 5.34 4.65 9.15 10.11 8.51l228.14-26.68c5.45-.64 9.77-5.48 9.65-10.82l-3.13-131.31c-.13-5.34-4.65-9.15-10.11-8.51l-132.48 15.49c-5.86.68-11.05-2.69-12.5-8.13z'
      style={{
        opacity: 0,
        fill: '#132696',
      }}
    />
  </svg>
);

export default OrangeFile;
