import * as React from "react";
import styled from 'styled-components';

const Container = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;


`

const NotFound = ({height, width, heading, subheading}) => (
 <Container>
  <svg 
  height={height} width={width} viewBox="0 0 100 100">
  
    <path d="M81.66 78.62H18.42l9.14-51.87h63.25zM49.21 21.38h-20.7l-.95 5.37h22.15z" />

    <path
      d="M81.66 78.62H18.42l9.14-51.87h63.25zM49.21 21.38h-20.7l-.95 5.37h22.15z"
      fill="grey"
    />

    <path
      d="M26.28 23.57c-.08-.3-.1-.6-.1-.9 0-.3.02-.6.1-.9.08.3.1.6.1.9 0 .3-.02.6-.1.9zM24.44 24.11c-.22-.22-.39-.47-.54-.73a3.42 3.42 0 0 1-.36-.83c.22.22.39.47.54.73.14.25.28.52.36.83zM23.11 25.49c-.3-.08-.57-.21-.83-.36s-.51-.32-.73-.54c.3.08.57.21.83.36s.51.31.73.54zM22.65 27.34c-.3.08-.6.1-.9.1-.3 0-.6-.02-.9-.1.3-.08.6-.1.9-.1.3 0 .6.02.9.1zM23.18 29.18c-.22.22-.47.39-.73.54-.26.15-.53.28-.83.36.22-.22.47-.39.73-.54.26-.14.53-.28.83-.36zM24.56 30.51c-.08.3-.21.57-.36.83s-.32.51-.54.73c.08-.3.21-.57.36-.83.16-.26.32-.51.54-.73zM26.42 30.97c.08.3.1.6.1.9 0 .3-.02.6-.1.9-.08-.3-.1-.6-.1-.9 0-.3.02-.6.1-.9zM28.26 30.44c.22.22.39.47.54.73.15.26.28.53.36.83-.22-.22-.39-.47-.54-.73-.14-.26-.28-.53-.36-.83zM29.59 29.06c.3.08.57.21.83.36s.51.32.73.54c-.3-.08-.57-.21-.83-.36s-.51-.32-.73-.54zM30.05 27.2c.3-.08.6-.1.9-.1.3 0 .6.02.9.1-.3.08-.6.1-.9.1-.3 0-.6-.02-.9-.1zM29.52 25.36c.22-.22.47-.39.73-.54.26-.15.53-.28.83-.36-.22.22-.47.39-.73.54-.26.15-.53.28-.83.36zM28.14 24.03c.08-.3.21-.57.36-.83s.32-.51.54-.73c-.08.3-.21.57-.36.83-.16.26-.32.51-.54.73z"
      fill="#263238"
    />
    <path d="M81.57 78.62H18.19l-9-49.98h63.38z" fill="#d9d9d9" />
    <circle cx={31.15} cy={56.56} r={2.63} fill="#898885"/>
    <circle  cx={51.98} cy={56.56} r={2.63} fill="#898885"/>
    <path
      d="m45.35 59.61-.14-.28c-.01-.02-1.37-2.54-4.11-2.54-2.5 0-3.11 2.37-3.13 2.47l-.07.3-.6-.15.07-.3c.01-.03.74-2.95 3.74-2.95 3.12 0 4.6 2.76 4.66 2.87l.14.28-.56.3z"
      fill="#898885"
    />
  </svg>
  <h1>{heading}</h1>
  <p>{subheading}</p>
  </Container>
 
 
);



export default NotFound;
