import {  createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const url= process.env.REACT_APP_BASEURL
const apiKey= process.env.REACT_APP_APIKEY

export const getDevProjects = createAsyncThunk('files/getFiles', async () => {
  const { data } = await axios.get(`${url}/api/devprojects/`,{
    headers: {
      'x-api-key': apiKey,
    },
  });
  return data;
});

export const devprojectSlice = createSlice({
  name: 'devprojects',
  initialState: {
    devprojects: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(getDevProjects.pending, (state) => {
      // Handle pending state
      state.loading = true;
    })
    .addCase(getDevProjects.fulfilled, (state, action) => {
      state.devprojects = [...state.devprojects, ...action.payload];
      state.loading = false;
      state.error = null;
    })
    .addCase(getDevProjects.rejected, (state, action) => {
      // Handle error
      state.loading = false;
      state.error = action.error.message;
    })
  },
});

// export const { setUser, clearUser } = authSlice.actions;

export default devprojectSlice.reducer;
