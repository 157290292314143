import React, { useState, useEffect } from 'react';
import Card from '../comp/DevCard';
// import projects from '../data/devprojects';
import styled from 'styled-components';
import { BsGlobe } from 'react-icons/bs';
import { AiFillAndroid } from 'react-icons/ai';
import { useSelector, useDispatch } from 'react-redux';
import { getDevProjects } from '../../features/projects/devprojectSlice';
import NotFound from '../../svg/NotFound';
import SkeletalLoad from '../comp/SkeletalLoad';

const ProjectsStyle = styled.div`
  display: grid;
  place-items: center;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  align-items: center;
  width: 100vw;
  /* gap: 1rem; */
  margin: 3rem 0;

  /**************************************************** */
  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    flex-direction: column;
    align-items: center;
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 2rem; */
  button {
    /* margin: 2rem; */
  }
  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background: blue;
    gap: 1rem;
    max-width: 8rem;
    width: 80%;
    color: var(--light);
    height: 3rem;
    border-radius: 2rem;
    outline: none;
    border: none;
    cursor: pointer;
    text-align: center;
    transition: 0.3s;
    :hover {
      box-shadow: 0px -35px 30px var(--brand1-blur);
    }
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const DevProjects = ({ id }) => {
  const dispatch = useDispatch();
  const { devprojects, loading } = useSelector((state) => state.devprojects);

  useEffect(() => {
    dispatch(getDevProjects());
  }, [dispatch]);
  const [page, setPage] = useState(0);
  const pageSize = 6;
  const totalPages = Math.ceil(devprojects.length / pageSize);

  const handleNextPage = () => {
    setPage((page) => page + 1);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handlePreviousPage = () => {
    setPage((page) => page - 1);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const sortedProjects = devprojects.slice().sort((a, b) => {
    return new Date(b.createdAt) - new Date(a.createdAt);
  });
  const startIndex = page * pageSize;
  const visibleProjects = sortedProjects.slice(
    startIndex,
    startIndex + pageSize
  );

  return (
    <Container>
      <ProjectsStyle id={id}>
        {devprojects ? (
          sortedProjects && sortedProjects.length > 0 ? (
            visibleProjects.map((project, index) => {
              return (
                <div>
                  <Card
                    key={project.id || <SkeletalLoad />}
                    title={project.name || <SkeletalLoad />}
                    img={project.url || <SkeletalLoad />}
                    vid={project.url || <SkeletalLoad />}
                    desc={project.desc || <SkeletalLoad />}
                    link={project.link || <SkeletalLoad />}
                    btnvalue={
                      project.status === true
                        ? 'Live'
                        : 'Soon!' || <SkeletalLoad />
                    }
                    type={
                      project.type === 'web' ? (
                        <BsGlobe className='iconStyles' />
                      ) : (
                        <AiFillAndroid className='iconStyles' />
                      )
                    }
                    stacks={project.stacks}
                    github={project.github}
                  />
                </div>
              );
            })
          ) : null
        ) : loading ? (
          <SkeletalLoad />
        ) : (
          <div className='not-found'>
            <NotFound width='4rem' heading='No projects found' />
          </div>
        )}
      </ProjectsStyle>
      <ButtonContainer>
        {totalPages > 1 && page > 0 ? (
          <button id='orangebtn' className='btn' onClick={handlePreviousPage}>
            Previous
          </button>
        ) : null}
        {page < totalPages - 1 && totalPages > 1 ? (
          <button id='bluebtn' className='btn' onClick={handleNextPage}>
            Next
          </button>
        ) : null}
      </ButtonContainer>
    </Container>
  );
};

export default DevProjects;
