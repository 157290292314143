import React, { useState } from 'react';

import styled, { keyframes } from 'styled-components';
import ReactPlayer from 'react-player';
import SubButton from './SubButton';
import { AiFillHtml5, AiOutlineInfoCircle } from 'react-icons/ai';
import { DiReact, DiCssTricks, DiMysql, DiNodejsSmall } from 'react-icons/di';
import {
  SiMongodb,
  SiJavascript,
  SiFirebase,
  SiTailwindcss,
  SiNextdotjs,
  SiBootstrap,
  SiPhp,
  SiCsharp,
  SiDotnet,
} from 'react-icons/si';
import { TbBrandReactNative } from 'react-icons/tb';
import { FaVuejs, FaLaravel } from 'react-icons/fa';
import { BiServer } from 'react-icons/bi';
import { BsWordpress } from 'react-icons/bs';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
`;

const CardStyle = styled.div`
  animation: ${({ animate }) => (animate ? fadeIn : 'none')} 0.5s ease;
  display: flex;
  flex-direction: column;
  width: 35rem;
  border-radius: 2rem 2rem 0rem 0rem;
  align-items: center;
  margin: 1rem;
  margin-bottom: 20rem;

  #tooltip {
    background-color: var(--dark);
    color: var(--light);
    padding: 0.5rem;
    border-radius: 0.5rem;
    transition: ease-in-out 0.5s;
  }

  @media only screen and (max-width: 768px) {
    width: 90%;
    /* margin: 0.5rem; */
    margin-bottom: 5rem;
  }
`;
const CardMedia = styled.div`
  width: inherit;
  height: 15rem;
  border-radius: 2rem 0rem 0rem 0rem;
  background: var(--grey);

  img,
  .card-image-sty {
    border-radius: inherit;
  }

  .react-player {
    border-radius: inherit;
    /* position: relative; */
    transform: translate(0, -101.8%);
  }
  /********************************** */
  @media only screen and (min-width: 920px) {
    height: 14rem;
  }
  @media only screen and (max-width: 768px) {
    height: 10rem;
    width: 23rem;
  }
`;

const CardDescription = styled.div`
  background: var(--light-grey);
  width: inherit;
  transition: height 0.5s ease;

  .show-desc {
    display: flex;
    flex-direction: column;
    width: inherit;
    padding: 1rem;
    height: 0;
    overflow: hidden;
    height: auto;
  }
  .hide-show-desc {
    display: none;
    height: 0;
  }
  #stack-flex {
    display: flex;
    color: var(--dark);
    margin: 1rem 0;
  }
  /******************************* */
  /* STACK COLORS */
  #stack-size {
    width: 1.5rem;
    margin: 0 0.3rem;
  }
  .html:hover {
    color: #dd4b25;
  }
  .css:hover {
    color: #2853de;
  }
  .tailwind:hover {
    color: #07b0ce;
  }
  .bootstrap:hover {
    color: #8712f7;
  }
  .javascript:hover {
    color: #e4d04b;
  }
  .react:hover {
    color: #46caf2;
  }
  .vue:hover {
    color: #3eb27f;
  }
  .react-native:hover {
    color: #1589c8;
  }
  .server:hover {
    color: #e83412;
  }
  .nextjs:hover {
    color: #616165;
  }
  .nodejs:hover {
    color: #62965d;
  }
  .csharp:hover {
    color: #64007a;
  }
  .dotnet:hover {
    color: #4d29c9;
  }
  .php:hover {
    color: #7175aa;
  }
  .laravel:hover {
    color: #e4392c;
  }
  .firebase:hover {
    color: #f2c12a;
  }
  .mongodb:hover {
    color: #00e15f;
  }
  .mysql:hover {
    color: #005c83;
  }
  .wordpress:hover {
    color: #1f6f93;
  }
  /***************************************** */
  @media only screen and (max-width: 768px) {
    width: 23rem;
  }
`;
const CardInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: inherit;
  height: 6.5rem;
  border-radius: 0rem 0rem 2rem 2rem;
  background: ${({ theme }) => theme.bodyColor};
  border: 1px solid var(--grey);
  :hover {
    box-shadow: 0px -35px 30px var(--brand1-blur);
  }
  #card-heading {
    font-size: 22px;
    align-items: left;
  }
  .cardinfo-top {
    display: flex;
    width: 30rem;
    margin-bottom: 0.3rem;
    justify-content: space-between;
    align-items: center;
  }
  .cardinfo-bottom {
    display: flex;
    justify-content: space-between;
    margin-top: 0.5rem;
    width: 30rem;
  }
  .info-icon {
    cursor: pointer;
  }
  @media only screen and (max-width: 768px) {
    width: 23rem;
    .cardinfo-top {
      width: 16rem;
    }
    #card-heading {
      font-size: 18px;
      align-items: left;
    }
    .cardinfo-bottom {
      width: 16rem;
    }
  }
`;
const LineDiv = styled.div`
  max-width: 100vw;
  width: 90%;
  height: 0.1px;
  background: var(--grey);
  opacity: 0.3;
  /******************************************* */
`;
/**************************************************** */
const icons = {
  html: <AiFillHtml5 className='html' id='stack-size' />,
  css: <DiCssTricks className='css' id='stack-size' />,
  tailwind: <SiTailwindcss className='tailwind' id='stack-size' />,
  bootstrap: <SiBootstrap className='bootstrap' id='stack-size' />,
  javascript: <SiJavascript className='javascript' id='stack-size' />,
  react: <DiReact className='react' id='stack-size' />,
  vue: <FaVuejs className='vue' id='stack-size' />,
  'react-native': (
    <TbBrandReactNative className='react-native' id='stack-size' />
  ),
  server: <BiServer className='server' id='stack-size' />,
  nextjs: <SiNextdotjs className='nextjs' id='stack-size' />,
  nodejs: <DiNodejsSmall className='nodejs' id='stack-size' />,
  'c#': <SiCsharp className='csharp' id='stack-size' />,
  '.net': <SiDotnet className='dotnet' id='stack-size' />,
  php: <SiPhp className='php' id='stack-size' />,
  laravel: <SiFirebase className='laravel' id='stack-size' />,
  firebase: <FaLaravel className='firebase' id='stack-size' />,
  mongodb: <SiMongodb className='mongodb' id='stack-size' />,
  sql: <DiMysql className='mysql' id='stack-size' />,
  wordpress: <BsWordpress className='wordpress' id='stack-size' />,
};

const Card = ({
  type,
  btnvalue,
  img,
  vid,
  title,
  link,
  stacks,
  desc,
  github,
}) => {
  const [showDesc, setShowDesc] = useState(false);
  return (
    <CardStyle>
      <CardMedia>
        <img src={img} alt='' className='card-img-sty' />

        <ReactPlayer
          className='react-player'
          url={vid}
          poster={img}
          type='video.mp4'
          width='100%'
          height='100%'
          loop={true}
          playing={true}
        />
      </CardMedia>
      <CardDescription>
        <div
          className={!showDesc ? 'hide-show-desc' : 'show-desc'}
          tabIndex={0}
        >
          <div style={{ color: 'var(--dark)' }}>{desc}</div>
          <div id='stack-flex'>
            {stacks.map((stack, index) => (
              <div key={index} className='anchor' data-tooltip-content={stack}>
                {icons[stack]}
                <Tooltip id='tooltip' anchorSelect='.anchor' />
              </div>
            ))}
          </div>
          <a target='blank' href={github}>
            <p style={{ color: '#e83412', textDecoration: 'underline' }}>
              Github
            </p>
          </a>
        </div>
      </CardDescription>
      <CardInfo>
        <div className='cardinfo-top'>
          <h1 id='card-heading'>{title}</h1>
          <SubButton
            href={link || undefined} // Make sure that link is either a valid URL or undefined
            type='type'
            value={btnvalue}
            colorname='bluebtn'
            isDisabled={!link} // Set isDisabled to true if link is falsy (null, undefined, or empty string)
          />
        </div>
        <LineDiv />
        <div className='cardinfo-bottom'>
          <div>{type}</div>
          <div className='info-icon'>
            <AiOutlineInfoCircle onClick={() => setShowDesc(!showDesc)} />
          </div>
        </div>
      </CardInfo>
    </CardStyle>
  );
};

export default Card;
