import styled, { keyframes } from 'styled-components';

// Keyframes for the loading animation
const loadingAnimation = keyframes`
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
`;

const SkeletalLoad = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: ${loadingAnimation} 1.5s infinite linear; /* Added 'linear' timing function */

  /* You can customize the size, animation speed, and colors as needed */
`;

export default SkeletalLoad;
