import * as React from 'react';
const UI = ({className}) => (
<svg  className={className}  x="0px" y="0px"
	 viewBox="0 0 24 24" >
<style type="text/css">
	
</style>
<g>
	<path className="ui0" d="M8.6,7.9v4.8c0,1.8,0.8,2.6,1.9,2.6c1.2,0,2-0.8,2-2.6V7.9h1.1v4.7c0,2.5-1.3,3.5-3.1,3.5
		c-1.7,0-2.9-0.9-2.9-3.5V7.9H8.6z"/>
	<path className="ui0" d="M16.4,7.9V16h-1V7.9H16.4z"/>
</g>
<circle className="ui1" cx="12" cy="12" r="9.9"/>
</svg>

);
export default UI;